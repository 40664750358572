// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-layout-js": () => import("/opt/build/repo/src/pages/blog/layout.js" /* webpackChunkName: "component---src-pages-blog-layout-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-source-js": () => import("/opt/build/repo/src/pages/open-source.js" /* webpackChunkName: "component---src-pages-open-source-js" */),
  "component---src-pages-success-stories-cloud-optimization-js": () => import("/opt/build/repo/src/pages/success-stories/cloud-optimization.js" /* webpackChunkName: "component---src-pages-success-stories-cloud-optimization-js" */),
  "component---src-pages-success-stories-cybersecurity-js": () => import("/opt/build/repo/src/pages/success-stories/cybersecurity.js" /* webpackChunkName: "component---src-pages-success-stories-cybersecurity-js" */),
  "component---src-pages-success-stories-devops-js": () => import("/opt/build/repo/src/pages/success-stories/devops.js" /* webpackChunkName: "component---src-pages-success-stories-devops-js" */),
  "component---src-pages-success-stories-index-js": () => import("/opt/build/repo/src/pages/success-stories/index.js" /* webpackChunkName: "component---src-pages-success-stories-index-js" */),
  "component---src-pages-success-stories-layout-js": () => import("/opt/build/repo/src/pages/success-stories/layout.js" /* webpackChunkName: "component---src-pages-success-stories-layout-js" */),
  "component---src-pages-success-stories-software-development-js": () => import("/opt/build/repo/src/pages/success-stories/software-development.js" /* webpackChunkName: "component---src-pages-success-stories-software-development-js" */),
  "component---src-pages-why-js": () => import("/opt/build/repo/src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

